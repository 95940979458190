import { createGiftCardRedeem } from "../api"
import { navigate } from "gatsby"

const redeemGiftCard = async (
  donationItemIds,
  isCoveringFees,
  honoreeData,
  subscribeMail,
  email,
  giftCardCode,
  totalPledged
) => {
  if (!donationItemIds) {
    // TODO: expose and handle bad data error
    console.warn(`invalid gift redeem: ${donationItemIds}`)
  }

  const { redeemId } = await createGiftCardRedeem(
    donationItemIds,
    isCoveringFees,
    honoreeData,
    subscribeMail,
    email,
    giftCardCode,
    totalPledged
  )

  // console.log(result)

  if (redeemId) {
    await navigate("/success-gift?id=" + redeemId)
  }
}

export default redeemGiftCard
