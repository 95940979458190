import React from "react"
import PropTypes from "prop-types"
import FamilyMetadata from "../FamilyMetadata"
import ProgressBar from "../ProgressBar"
import ShareButtons from "../ShareButtons"

import styles from "./styles.module.scss"

const FamilyHero = ({
  data,
  totalAmountNeeded,
  totalPending,
  totalPledged,
}) => {
  const {
    display_name: name,
    situation,
    members,
    location_text: locationText,
    organization,
  } = data

  return (
    <div className={styles.familyHero}>
      <h2 className="large-header">{`Meet ${name}`}</h2>
      <FamilyMetadata
        members={members}
        location={locationText}
        organization={organization.name || "Local Organization"}
      />
      <div className={`${styles.progressBar}`}>
        <ProgressBar
          total={totalAmountNeeded}
          amountPending={totalPending}
          amountProposed={totalPledged}
        />
      </div>
      <p className={`body-copy ${styles.situation}`}>{situation}</p>
      <ShareButtons emailBody={`Meet ${name}, a neighbor in need: `} />
    </div>
  )
}

FamilyHero.propTypes = {
  data: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    members: PropTypes.string.isRequired,
    situation: PropTypes.string.isRequired,
  }),
}

export default FamilyHero
