import React, { useEffect } from "react"
import { useQuery } from "@apollo/client"
// components
import FamilyPage from "../components/FamilyPage"
import Loading from "../components/Loading"
import FamilySuggestions from "../components/FamilySuggestions"
import SEO from "../components/SEO"
// functions
import redirectToCheckout from "../utils/redirect-checkout"
import redeemGiftCard from "../utils/redeem-gift-card"

import { logEventGA } from "../utils/log"
import { getTransactionFees } from "../../functions/utils/calc"
import { navigate } from "gatsby"

import { GET_FAMILY_BY_ID } from "../queries/findFamilyByID"

function Family(args) {
  // branch on whether we got here via server or client routing
  const cameViaClientRouting = !!args.familyId
  let familyId
  let familyCoreInfo

  if (cameViaClientRouting) {
    familyId = args.familyId
    familyCoreInfo = null
  } else {
    familyId = args.pageContext.family_id
    // familyCoreInfo = subset of the full family data preloaded on server,
    // for web crawlers to generate proper family-specific link previews
    familyCoreInfo = args.pageContext
  }

  useEffect(() => {
    if (!window) return
    if (!familyId) {
      navigate("/")
    }
  }, [familyId])

  const { loading, data, error } = useQuery(GET_FAMILY_BY_ID, {
    variables: {
      id: familyId.toString(),
    },
  })

  const seoComponent = familyCoreInfo ? (
    <SEO
      title={`NeighborShare · Meet ${familyCoreInfo.display_name}`}
      description={familyCoreInfo.situation}
    />
  ) : null

  if (loading) {
    return (
      <>
        {seoComponent}
        <Loading />
      </>
    )
  }
  if (error) {
    navigate("/error-page")
  }

  // Successfully loaded; no error
  const family = data.families

  const handleCheckoutClick = async (
    selectedNeeds,
    isCoveringFees,
    honoreeData,
    subscribeMail,
    giftCardCode
  ) => {
    if (!family) return
    if (family.organization) {
      const itemIDs = selectedNeeds.map(item => item.need_id)

      let totalValue = selectedNeeds.reduce((total, need) => {
        return total + need.value
      }, 0)

      if (isCoveringFees) {
        totalValue += getTransactionFees(totalValue)
      }

      logEventGA("begin_checkout", {
        currency: "USD",
        totalValue,
        items: itemIDs,
      })

      await redirectToCheckout(
        itemIDs,
        isCoveringFees,
        honoreeData,
        subscribeMail,
        giftCardCode
      )
    }
  }

  const handleGiftRedeemClick = (
    selectedNeeds,
    isCoveringFees,
    honoreeData,
    subscribeMail,
    email,
    giftCardCode,
    totalPledged
  ) => {
    if (!family) return
    if (family.organization) {
      const itemIDs = selectedNeeds.map(item => item.need_id)

      // logEventGA("begin_checkout", {
      //   currency: "USD",
      //   totalValue,
      //   items: itemIDs,
      // })

      return redeemGiftCard(
        itemIDs,
        isCoveringFees,
        honoreeData,
        subscribeMail,
        email,
        giftCardCode,
        totalPledged
      )
    }
  }

  return (
    <>
      {seoComponent}
      {family && (
        <>
          <FamilyPage
            family={family}
            handleCheckoutClick={handleCheckoutClick}
            handleGiftRedeemClick={handleGiftRedeemClick}
          />
          <FamilySuggestions
            title="Other households in need"
            currentFamilyId={familyId}
          />
        </>
      )}
    </>
  )
}

export default Family
