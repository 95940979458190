import React, { useState, useEffect } from "react"
import ButtonCustom from "../ButtonCustom"
import styles from "./styles.module.scss"
import { formatMoney } from "../../utils/format"
import { useNeedsContext } from "../../context/needsContext"

const CheckoutBanner = ({ checkoutLoading, handleRedirectToHonorModal }) => {
  const { selectedNeeds, clearSelectedNeeds } = useNeedsContext()
  const [popoverOpen, setPopoverOpen] = useState(false)

  // Check localStorage to see if the user has seen the fee popover before. If not, flash it for 2.5 seconds.
  useEffect(() => {
    if (!localStorage.getItem("nbshare-seen-fee-popover")) {
      setTimeout(() => setPopoverOpen(true), 1100)
      setTimeout(() => setPopoverOpen(false), 5100)
      localStorage.setItem("nbshare-seen-fee-popover", true)
    }
  }, [selectedNeeds])

  const selectedNeedsAmount = selectedNeeds
    .map(need => need.value)
    .reduce((total, value) => {
      return total + value
    }, 0)

  const selectedNeedsText = `${selectedNeeds.length} ${
    selectedNeeds.length === 1 ? "need" : "needs"
  } selected `

  return (
    <div className={`wrapper ${styles.needsCheckout}`}>
      <div className={styles.footerRow}>
        <div className={`${styles.checkoutAction} tiny-body-copy`}>
          {selectedNeedsText}
          <button
            className={styles.transactionFeeLinkContainer}
            onMouseEnter={() => setPopoverOpen(true)}
            onMouseLeave={() => setPopoverOpen(false)}
            onClick={() => setPopoverOpen(!popoverOpen)}
          >
            {formatMoney(selectedNeedsAmount)}
          </button>
          <button
            className={styles.emptyCartButton}
            onClick={clearSelectedNeeds}
          >
            Empty cart
          </button>
        </div>
        <div className={styles.checkoutAction}>
          <ButtonCustom
            className={styles.checkoutButton}
            icon="heart"
            iconPulse
            iconClassName={styles.checkoutButtonIcon}
            size="medium"
            loading={checkoutLoading}
            handleClick={handleRedirectToHonorModal}
          >
            Checkout
          </ButtonCustom>
        </div>
      </div>
    </div>
  )
}

export default CheckoutBanner
