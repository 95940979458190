import React, { useState } from "react"

import FamilyHero from "../FamilyHero"
import FamilyNeeds from "../FamilyNeeds"
import CheckoutBanner from "../CheckoutBanner"
import Modal, { ModalType } from "../Modal"
import { useNeedsContext } from "../../context/needsContext"

const getAvailableNeeds = needs => {
  if (!needs) {
    return []
  }

  // key: $ amount
  // value: array of needs of that $ amount
  let valueToNeeds = {}
  for (let need of needs) {
    if (need.is_available) {
      if (valueToNeeds.hasOwnProperty(need.value)) {
        valueToNeeds[need.value].push(need)
      } else {
        valueToNeeds[need.value] = [need]
      }
    }
  }

  let availableNeeds = []
  let seenTypes = new Set()
  while (Object.keys(valueToNeeds).length > 0) {
    const values = Object.keys(valueToNeeds).sort((a, b) => a < b)
    for (let value of values) {
      const currValueNeeds = valueToNeeds[value]
      let i = 0
      while (seenTypes.has(currValueNeeds[i].need_type)) {
        // Try to use a new need type if possible
        i++
        if (i === currValueNeeds.length) {
          i = 0
          break
        }
      }
      seenTypes.add(currValueNeeds[i].need_type)
      availableNeeds.push(currValueNeeds.splice(i, 1)[0])

      if (valueToNeeds[value].length === 0) {
        delete valueToNeeds[value]
      }
    }
  }
  return availableNeeds
}

const FamilyPage = ({ family, handleCheckoutClick, handleGiftRedeemClick }) => {
  // console.log("Loading Page")
  const { selectedNeeds } = useNeedsContext()
  const { display_name: name, needs } = family

  // console.log("getting needs")
  const availableNeeds = getAvailableNeeds(needs)

  const coveredNeeds = (needs || [])
    .filter(need => !need.is_available)
    .sort((a, b) => b.value - a.value)

  // console.log("finding covered needs")

  const allNeeds = [...availableNeeds, ...coveredNeeds]
  const [coverFeesToggled, toggleCoverFees] = useState(true)
  const [honorModalOpen, setHonorModalOpen] = useState(false)
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [modalCheckoutLoading, setModalCheckoutLoading] = useState(false)
  const [giftRedeemResult, setGiftRedeemResult] = useState(false)

  const totalAmountNeeded = allNeeds
    .map(need => need.value)
    .reduce((total, val) => {
      return total + val
    }, 0)

  const totalPending = availableNeeds.reduce((total, need) => {
    return total + (need.value || 0)
  }, 0)

  const totalPledged = selectedNeeds.reduce((total, need) => {
    return total + (need.value || 0)
  }, 0)

  const pledgedProgress = selectedNeeds
    .filter(need =>
      availableNeeds.some(
        availableNeed => availableNeed.need_id === need.need_id
      )
    )
    .reduce((total, need) => {
      return total + (need.value || 0)
    }, 0)

  // Removing Honoree
  // const handleCheckoutButtonClick = (honoreeData = {}, subscribeMail) => {
  //   if (!modalCheckoutLoading) {
  //     setModalCheckoutLoading(true)
  //     handleCheckoutClick(
  //         selectedNeeds,
  //         coverFeesToggled,
  //         honoreeData,
  //         subscribeMail
  //     )
  //   }
  // }

  const handleCheckoutButtonClick = (
    honoreeData = {},
    subscribeMail,
    giftCardCode
  ) => {
    if (!modalCheckoutLoading) {
      setModalCheckoutLoading(true)
      handleCheckoutClick(
        selectedNeeds,
        coverFeesToggled,
        honoreeData,
        subscribeMail,
        giftCardCode
      )
    }
  }

  const handleGiftRedeemButtonClick = async (
    honoreeData = {},
    subscribeMail,
    email,
    giftCardCode
  ) => {
    if (!modalCheckoutLoading) {
      const result = await handleGiftRedeemClick(
        selectedNeeds,
        coverFeesToggled,
        honoreeData,
        subscribeMail,
        email,
        giftCardCode,
        totalPledged
      )
      setGiftRedeemResult(result)
    }
  }

  const handleRedirectToHonorModal = () => {
    setCheckoutLoading(true)
    setHonorModalOpen(true)
  }

  const triggerCloseHonorModal = () => {
    setHonorModalOpen(false)
    setCheckoutLoading(false)
  }

  return (
    <>
      <Modal
        modalType={ModalType.Donate}
        handleModalClose={triggerCloseHonorModal}
        modalOpen={honorModalOpen}
        modalCheckoutLoading={modalCheckoutLoading}
        handleCheckoutButtonClick={handleCheckoutButtonClick}
        handleGiftRedeemButtonClick={handleGiftRedeemButtonClick}
        totalPledged={totalPledged}
        giftRedeemResult={giftRedeemResult}
        setGiftRedeemResult={setGiftRedeemResult}
        coverFeesToggled={coverFeesToggled}
        toggleCoverFees={toggleCoverFees}
      />
      <FamilyHero
        data={family}
        allNeeds={allNeeds}
        totalAmountNeeded={totalAmountNeeded}
        totalPending={totalPending}
        totalPledged={pledgedProgress}
      />
      <FamilyNeeds
        displayName={name}
        allNeeds={allNeeds}
        availableNeeds={availableNeeds}
      />
      {selectedNeeds.length > 0 && (
        <CheckoutBanner
          checkoutLoading={checkoutLoading}
          handleRedirectToHonorModal={handleRedirectToHonorModal}
        />
      )}
    </>
  )
}

export default FamilyPage
