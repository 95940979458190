import React from "react"
import PropTypes from "prop-types"
import Icon from "../Icon"
import styles from "./styles.module.scss"
import { DONATION_TYPES } from "../../constants/donation-types"

const FamilyMetadata = ({ members, location, organization }) => {
  return (
    <ul className={`no-list list-horizontal ${styles.familyMetadata}`}>
      <li className={styles.metadata}>
        <Icon aesthetic="minimal" icon={DONATION_TYPES.CHILDCARE} />
        <p className="tiny-body-copy">{members}</p>
      </li>
      <li className={styles.metadata}>
        <Icon aesthetic="minimal" icon={DONATION_TYPES.HOUSING} />
        <p className="tiny-body-copy">{location}</p>
      </li>
      <li className={styles.metadata}>
        <Icon aesthetic="minimal" icon={DONATION_TYPES.OTHER} />
        <p className="tiny-body-copy">{organization}</p>
      </li>
    </ul>
  )
}

FamilyMetadata.defaultProps = {
  organization: "Carver Foundation",
}

FamilyMetadata.propTypes = {
  members: PropTypes.string,
  location: PropTypes.string,
  organization: PropTypes.string,
}

export default FamilyMetadata
