import React, { useState } from "react"

import GridSection from "../GridSection"
import CardDonation from "../CardDonation"
import CardLast from "../CardLast"

import styles from "./styles.module.scss"
import { formatMoney } from "../../utils/format"
import { useNeedsContext } from "../../context/needsContext"

const FamilyNeeds = ({
  displayName,
  allNeeds,
  availableNeeds,
  checkoutLoading,
}) => {
  const {
    selectedNeeds,
    addNewNeed,
    addMany,
    deleteNeed,
    deleteMany,
  } = useNeedsContext()
  const [buyAllToggled, toggleBuyAll] = useState(false)

  const handleDonationClick = need => {
    if (checkoutLoading) return

    const selectedNeedIndex = selectedNeeds.findIndex(
      ({ need_id }) => need_id === need.need_id
    )

    if (selectedNeedIndex === -1) {
      addNewNeed(need)
    } else {
      deleteNeed(selectedNeedIndex)
    }
  }

  const handleBuyAll = () => {
    if (checkoutLoading) return
    if (buyAllToggled) {
      deleteMany(availableNeeds)
      toggleBuyAll(false)
      return
    }
    addMany(availableNeeds)
    toggleBuyAll(true)
  }

  const totalAmountNeeded = availableNeeds
    .map(need => need.value)
    .reduce((total, val) => {
      return total + val
    }, 0)

  const donateAllDescription = `
    Take care of all of ${displayName}'s needs, totaling ${formatMoney(
    totalAmountNeeded
  )}.`

  return (
    <section>
      <GridSection bgColor="cream">
        {allNeeds.length > 0 && (
          <ul>
            {availableNeeds.length > 2 && (
              <CardLast
                title={"Cover everything"}
                description={donateAllDescription}
                handleClick={handleBuyAll}
                buttonLabel={buyAllToggled ? "All needs" : "Donate all"}
                buttonIcon={buyAllToggled && "check"}
                buttonClassName={styles.checkoutButton}
                buttonIconClassName={buyAllToggled && styles.checkoutButtonIcon}
              />
            )}
            {allNeeds.map(need => (
              <li key={need.need_id}>
                <CardDonation
                  data={need}
                  isSelected={
                    selectedNeeds.findIndex(
                      ({ need_id }) => need_id === need.need_id
                    ) !== -1
                  }
                  handleClick={handleDonationClick}
                />
              </li>
            ))}
          </ul>
        )}
      </GridSection>
    </section>
  )
}

export default FamilyNeeds
