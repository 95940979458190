import { gql } from "@apollo/client"

export const GET_FAMILY_BY_ID = gql`
  query GetFamilyByID($id: uuid!) {
    families: families_by_pk(family_id: $id) {
      family_id
      location_text
      members
      display_name
      situation
      size
      needs {
        need_id
        description
        is_available
        name
        value
        need_type
        needTypeByNeedType {
          display_name
        }
      }
      organization {
        name
        stripe_account_id
      }
    }
  }
`
