import React from "react"
import PropTypes from "prop-types"

import { formatMoney } from "../../utils/format"
import Icon from "../Icon"
import styles from "./styles.module.scss"

const ProgressBar = ({ amountPending, total, amountProposed }) => {
  const percentCovered = ((total - amountPending) / total) * 100

  const pctProposed = amountProposed ? (amountProposed / total) * 100 : 0
  const remainder = 100 - percentCovered - pctProposed
  const leftTotalBar = percentCovered + pctProposed
  const nominalAmountRemaining = amountPending - amountProposed

  return (
    <div className={styles.container}>
      <div className={styles.meter}>
        <span
          className={styles.covered}
          style={{ width: `${percentCovered}%` }}
        />
        {pctProposed > 0 && (
          <span
            className={styles.proposed}
            style={{ left: `${percentCovered}%`, width: `${pctProposed}%` }}
          />
        )}
        {remainder > 0 && (
          <span
            className={styles.total}
            style={{ left: `${leftTotalBar}%`, width: `${remainder}%` }}
          />
        )}
      </div>
      <div className={styles.pending}>
        {remainder !== 0 ? (
          <span className={styles.remainder}>
            {formatMoney(nominalAmountRemaining)} left
          </span>
        ) : (
          <Icon
            className={styles.complete}
            icon={"heart"}
            aesthetic={"minimal"}
          />
        )}
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  amountPending: PropTypes.string,
  amountProposed: PropTypes.string,
  total: PropTypes.string,
}

export default ProgressBar
